import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/users/parameter",
    name: "userParameter",
    meta: {
      title: "Paramètres",
      authRequired: true,
    },
    component: () => import("../views/account/parameter.vue")

  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
      key: "dashboard"
    },
    component: () => import("../views/dashboard/simple/index.vue"),
  },
  {
    path: "/dashboard/collecte",
    name: "dashboardCollecte",
    meta: {
      title: "Dashboard",
      authRequired: true,
      key: "dashboard-collecte"
    },
    component: () => import("../views/dashboard/simple/index-collecte.vue"),
  },

  {
    path: "/collectivites",
    name: "collectivite",
    meta: {
      title: "Dashboard",
      authRequired: true,
      key: "klp-collectivite"
    },
    children:[
      {
        path: "contribuables",
        name: "contribuables",
        meta: {
          title: "Contribuables",
          authRequired: true,
          key: "klp-contribuables"
        },
        children:[
          {
            path: "",
            name: "listeContribuables",
            meta: {
              title: "Contribuables",
              authRequired: true,
              key: "list-contribuable"
            },
            component: () => import("../views/collectivites/contribuables/index.vue"),
          },
          {
            path: "print",
            name: "printContribuables",
            meta: {
              title: "Contribuables",
              authRequired: true,
              key: "print-contribuable"
            },
            component: () => import("../views/collectivites/contribuables/print.vue")

          },
          {
            path: "print-en-regle",
            name: "printContribuableEnRegle",
            meta: {
              title: "Contribuables",
              authRequired: true,
              key: "print-contribuable-paid"
            },
            component: () => import("../views/collectivites/contribuables/printEnRegle.vue")

          },
          {
            path: "print-impaye",
            name: "printContribuableImpaye",
            meta: {
              title: "Contribuables",
              authRequired: true,
              key: "print-contribuable-impaye"
            },
            component: () => import("../views/collectivites/contribuables/printImpaye.vue")

          },
          {
            path: ":id/fiche",
            name: "ficheContribuables",
            meta: {
              title: "Contribuables",
              authRequired: true,
              key: "fiche-contribuable"
            },
            component: () => import("../views/collectivites/contribuables/fiche.vue")

          }
        ]
      },
      {
        path: "equipements",
        name: "equipements",
        meta: {
          title: "Equipements",
          authRequired: true,
          key: "equipement"
        },
        children:[
          {
            path: "",
            name: "listeEquipements",
            meta: {
              title: "Equipements",
              authRequired: true,
              key: "list-equipement"
            },
            component: () => import("../views/collectivites/equipements/index.vue")

          },
          {
            path: "print",
            name: "printEquipements",
            meta: {
              title: "Equipements",
              authRequired: true,
              key: "print-equipement"
            },
            component: () => import("../views/collectivites/equipements/print.vue")

          },
          {
            path: "print-en-regle",
            name: "printEquipementsEnRegle",
            meta: {
              title: "Equipements",
              authRequired: true,
              key: "print-equipement-en-regle"
            },
            component: () => import("../views/collectivites/equipements/printEnRegle.vue")

          },
          {
            path: "print-en-impaye",
            name: "printEquipementsImpaye",
            meta: {
              title: "Equipements",
              authRequired: true,
              key: "print-equipement-impaye"
            },
            component: () => import("../views/collectivites/equipements/printImpaye.vue")

          },

        ]
      },
      {
        path: "transactions",
        name: "transactions",
        meta: {
          title: "Transactions",
          authRequired: true,
          key: "transaction"
        },
        children:[
          {
            path: "",
            name: "listeTransactions",
            meta: {
              title: "Transactions",
              authRequired: true,
              key: "list-transaction"
            },
            component: () => import("../views/collectivites/transactions/index.vue")

          },
          {
            path: "print-transaction",
            name: "printTransactions",
            meta: {
              title: "Transactions",
              authRequired: true,
              key: "print-transactions"
            },
            component: () => import("../views/collectivites/transactions/print.vue")

          },
          {
            path: "print-transaction-moyen-de-paiement",
            name: "printTransactionsMoyenPaiement",
            meta: {
              title: "Transactions par moyen de paiement",
              authRequired: true,
              key: "print-transactions-moyen-de-paiement"
            },
            component: () => import("../views/collectivites/transactions/printMoyenPaiement.vue")

          },
        ]
      },
      {
        path: "agents",
        name: "agents",
        meta: {
          title: "Agent",
          authRequired: true,
        },
        children: [
          {
            path: "",
            name: "listeAgent",
            meta: {
              title: "Agents",
              authRequired: true,
              key: "list-agent"
            },
            component: () => import("../views/collectivites/agents/liste")

          },
          {
            path: "create",
            name: "createAgent",
            meta: {
              title: "Agents",
              authRequired: true,
              key: "add-agent"
            },
            component: () => import("../views/collectivites/agents/create")

          },
          {
            path: ":id/edit",
            name: "editAgent",
            meta: {
              title: "Agents",
              authRequired: true,
              key: "edit-agent"
            },
            component: () => import("../views/collectivites/agents/edit")

          },
          {
            path: ':id/fiche-agent',
            name: 'ficheAgent',
            meta: {
              title: "Agents",
              authRequired: true,
              key: "fiche-agent"
            },
            component: () => import("../views/collectivites/agents/fiche.vue")
          },
          {
            path: ':id/affectations',
            name: 'affectationAgent',
            meta: {
              title: "Agents",
              authRequired: true,
              key: "affectation-agent"
            },
            component: () => import("../views/collectivites/agents/affectation.vue")
          }

        ]

      },
      {
        path: "collectes",
        name: "collectes",
        meta: {
          title: "Collectes",
          authRequired: true,
          key: "collecte"
        },
        children:[
          {
            path: "",
            name: "listCollecte",
            meta: {
              title: "Transactions",
              authRequired: true,
              key: "list-collecte"
            },
            component: () => import("../views/collectivites/collectes/liste")

          },
        ]
      },
      {
        path: "terminaux",
        name: "terminaux",
        meta: {
          title: "TPE",
          authRequired: true,
          key: "terminaux"
        },
        children:[
          {
            path: "",
            name: "listeTerminal",
            meta: {
              title: "Terminaux",
              authRequired: true,
              key: "list-terminaux"
            },
            component: () => import("../views/collectivites/terminaux/liste")

          },
          {
            path: "create",
            name: "createTerminal",
            meta: {
              title: "Terminaux",
              authRequired: true,
              key: "add-terminaux"
            },
            component: () => import("../views/collectivites/terminaux/create")

          },
          {
            path: ":id/edit",
            name: "editTerminal",
            meta: {
              title: "Terminaux",
              authRequired: true,
              key: "edit-terminaux"
            },
            component: () => import("../views/collectivites/terminaux/edit")

          },
        ]
      },
      {
        path: "configurations",
        name: "configurations",
        meta: {
          title: "Configurations",
          authRequired: true,
          key: "collectivite-configurations"
        },
        children: [
          {
            path: "sous-entites",
            name: "ramifications",
            meta: {
              title: "Sous-entités",
              authRequired: true,
              key: "sous-entites"
            },
            children:[
              {
                path: "",
                name: "listeSousEntites",
                meta: {
                  title: "Sous-entités",
                  authRequired: true,
                  key: "list-ramification"
                },
                component: () => import("../views/collectivites/configurations/ramifications/liste")

              },
              {
                path: "create",
                name: "createRamification",
                meta: {
                  title: "Ramifications",
                  authRequired: true,
                  key: "add-ramification"
                },
                component: () => import("../views/collectivites/configurations/ramifications/create")

              },
              {
                path: ":id/add",
                name: "addRamification",
                meta: {
                  title: "Ramifications",
                  authRequired: true,
                  key: "create-ramification"
                },
                component: () => import("../views/collectivites/configurations/ramifications/add")

              },
              {
                path: ":id/edit",
                name: "editRamification",
                meta: {
                  title: "Sous-entités",
                  authRequired: true,
                  key: "edit-ramification"
                },
                component: () => import("../views/collectivites/configurations/ramifications/edit")

              }
            ]
          },

        ]

      },
      {
        path: "categorie-activites",
        name: "categorieActivite",
        meta: {
          title: "Nature d'activité",
          authRequired: true,
          key: "categorie-activite"
        },
        children:[
          {
            path: "",
            name: "listCategorieActivite",
            meta: {
              title: "Catégorie d'activité",
              authRequired: true,
              key: "list-categorie-activite"
            },
            component: () => import("../views/collectivites/configurations/categorie_activite/liste.vue")

          },
          {
            path: "create",
            name: "createCategorieActivite",
            meta: {
              title: "Catégorie d'activité",
              authRequired: true,
              key: "add-categorie-activite"
            },
            component: () => import("../views/collectivites/configurations/categorie_activite/create.vue")

          },
          {
            path: ":id/edit",
            name: "editCategorieActivite",
            meta: {
              title: "Categorie d'activité",
              authRequired: true,
              key: "edit-categorie-activite"
            },
            component: () => import("../views/collectivites/configurations/categorie_activite/edit.vue")

          },

        ]
      },
      {
        path: "types-equipements",
        name: "types-equipements",
        meta: {
          title: "Types équipements",
          authRequired: true,
        },
        children:[
          {
            path: "",
            name: "listeTypesEquipements",
            meta: {
              title: "Types équipements",
              authRequired: true,
              key: "list-type-equipement"
            },
            component: () => import("../views/collectivites/configurations/types-equipements/liste")

          },
          {
            path: "create",
            name: "createTypesEquipements",
            meta: {
              title: "Types équipements",
              authRequired: true,
              key: "add-type-equipement"
            },
            component: () => import("../views/collectivites/configurations/types-equipements/create")

          },
          {
            path: ":id/edit",
            name: "editTypesEquipements",
            meta: {
              title: "Types équipements",
              authRequired: true,
              key: "edit-type-equipement"
            },
            component: () => import("../views/collectivites/configurations/types-equipements/edit")

          }
        ]
      },
      {
        path: "nature-activites",
        name: "natureActivite",
        meta: {
          title: "Nature d'activité",
          authRequired: true,
          key: "nature-activite"
        },
        children:[
          {
            path: "",
            name: "listNatureActivite",
            meta: {
              title: "Nature d'activité",
              authRequired: true,
              key: "list-nature-activite"
            },
            component: () => import("../views/collectivites/configurations/nature_activite/liste.vue")

          },
          {
            path: "create",
            name: "createNatureActivite",
            meta: {
              title: "Nature d'activité",
              authRequired: true,
              key: "add-nature-activite"
            },
            component: () => import("../views/collectivites/configurations/nature_activite/create.vue")

          },
          {
            path: ":id/edit",
            name: "editNatureActivite",
            meta: {
              title: "Nature d'activité",
              authRequired: true,
              key: "edit-nature-activite"
            },
            component: () => import("../views/collectivites/configurations/nature_activite/edit.vue")

          },

        ]
      },
      {
        path: "base-taxables",
        name: "baseTaxables",
        meta: {
          title: "Base Taxable",
          authRequired: true,
          key: "taxe"
        },
        children:[
          {
            path: "categorie",
            name: "CategorieBaseTaxables",
            meta: {
              title: "Categories taxables",
              authRequired: true,
              key: "categorie-taxe"
            },
            children:[
              {
                path: "",
                name: "listeCategorieBaseTaxables",
                meta: {
                  title: "Catégorie de taxe",
                  authRequired: true,
                  key: "list-categorie-taxe"
                },
                component: () => import("../views/collectivites/configurations/base_taxables/categorie/liste")

              },
              {
                path: "create",
                name: "createCategorieBaseTaxables",
                meta: {
                  title: "Catégorie de taxe",
                  authRequired: true,
                  key: "add-categorie-taxe"
                },
                component: () => import("../views/collectivites/configurations/base_taxables/categorie/create")

              },
              {
                path: ":id/edit",
                name: "editCategorieBaseTaxables",
                meta: {
                  title: "Catégorie de taxe",
                  authRequired: true,
                  key: "edit-categorie-taxe"
                },
                component: () => import("../views/collectivites/configurations/base_taxables/categorie/edit.vue")

              },
            ]

          },
          {
            path: "",
            name: "listeBaseTaxables",
            meta: {
              title: "Base taxable",
              authRequired: true,
              key: "list-taxe"
            },
            component: () => import("../views/collectivites/configurations/base_taxables/liste.vue")

          },
          {
            path: "create",
            name: "createBaseTaxables",
            meta: {
              title: "Base taxable",
              authRequired: true,
              key: "add-taxe"
            },
            component: () => import("../views/collectivites/configurations/base_taxables/create.vue")

          },
          {
            path: ":id/edit",
            name: "editBaseTaxables",
            meta: {
              title: "Base taxable",
              authRequired: true,
              key: "edit-taxe"
            },
            component: () => import("../views/collectivites/configurations/base_taxables/edit.vue")

          },

        ]
      },

    ]
  },
  {
    path: "/rapports",
    name: "rapports",
    meta: {
      title: "Rapports",
      authRequired: true,
      key: "rapports"
    },
    children: [
      {
        path: "",
        name: "indexRapports",
        meta: {
          title: "Rapports",
          authRequired: true,
          key: "index-rapports"
        },
        component: () => import("../views/rapports/index.vue")

      },
      {
        path: "rapports-des-transactions",
        name: "releveTransactions",
        meta: {
          title: "Relevé transactions",
          authRequired: true,
          key: "releve-transactions"
        },
        component: () => import("../views/rapports/releveTransactions.vue")

      },
      {
        path: "rapports-des-enrolements",
        name: "releveEnrolements",
        meta: {
          title: "Relevé des enrolements",
          authRequired: true,
          key: "releve-enrolements"
        },
        component: () => import("../views/rapports/releveEnrolement.vue")

      },
      {
        path: "rapports-des-impayes",
        name: "releveImpayes",
        meta: {
          title: "Relevé des impayés",
          authRequired: true,
          key: "releve-impayés"
        },
        component: () => import("../views/rapports/releveImpaye.vue")

      }
    ]
  },
  {
    path: "/administration",
    name: "administrations",
    meta: {
      title: "Administration",
      authRequired: true,
    },
    children: [
      {
        path: "collectivite",
        name: "administrationCollectivite",
        meta: {
          title: "Collectivité",
          authRequired: true,
        },
        children: [
          {
            path: "",
            name: "listeAdminCollectivite",
            meta: {
              title: "Collectivité",
              authRequired: true,
              key: "list-collectivite"
            },
            component: () => import("../views/administration/collectivite/liste")

          },
          {
            path: "create",
            name: "createAdminCollectivite",
            meta: {
              title: "Collectivité",
              authRequired: true,
              key: "add-collectivite",

            },
            component: () => import("../views/administration/collectivite/create")

          },
          {
            path: ":id/edit",
            name: "editAdminCollectivite",
            meta: {
              title: "Collectivité",
              authRequired: true,
              key: "edit-collectivite",

            },

            component: () => import("../views/administration/collectivite/edit")

          },

        ]

      },
      {
        path: "centre-etat-civil",
        name: "administrationCentreEtatCivil",
        meta: {
          title: "Administration",
          authRequired: true,
        },
        children: [
          {
            path: "",
            name: "listeAdminCentre",
            meta: {
              title: "Administration",
              authRequired: true,
              key: "list-admin-centre"
            },
            component: () => import("../views/administration/centres/liste")

          },
          {
            path: "create",
            name: "createAdminCentre",
            meta: {
              title: "Centre d'état civil",
              authRequired: true,
              key: "add-admin-centre",

            },
            component: () => import("../views/administration/centres/create")

          },
          {
            path: ":id/edit",
            name: "editAdminCentre",
            meta: {
              title: "Centre d'état civil",
              authRequired: true,
              key: "edit-admin-centre",

            },

            component: () => import("../views/administration/centres/edit")

          },

        ]

      },
      {
        path: "sous-entites",
        name: "administrationSousEntite",
        meta: {
          title: "Collectivité",
          authRequired: true,
        },
        children: [
          {
            path: "",
            name: "listeAdminRamification",
            meta: {
              title: "Sous-entités",
              key: "list-type-ramification",
              authRequired: true,
            },
            component: () => import("../views/administration/ramification/liste")

          },
          {
            path: "create",
            name: "createAdminRamification",
            meta: {
              title: "Collectivité",
              authRequired: true,
              key: "add-type-ramification",

            },
            component: () => import("../views/administration/ramification/create")

          },
          {
            path: ":id/edit",
            name: "editAdminRamification",
            meta: {
              title: "Collectivité",
              authRequired: true,
              key: "edit-type-ramification",

            },
            component: () => import("../views/administration/ramification/edit")

          },

        ]

      },
      {
        path: "roles",
        name: "administrationRoles",
        meta: {
          title: "Role utilisateur",
          authRequired: true,
        },
        children: [
          {
            path: "",
            name: "listeRoleUtilisateur",
            meta: {
              title: "Groupes",
              authRequired: true,
              key: 'list-role'
            },
            component: () => import("../views/administration/roles/liste")

          },
          {
            path: "create",
            name: "createRoleUtilisateur",
            meta: {
              title: "Collectivité",
              authRequired: true,
              key: 'add-role'

            },
            component: () => import("../views/administration/roles/create")

          },
          {
            path: ":id/edit",
            name: "editRoleUtilisateur",
            meta: {
              title: "Rôles",
              authRequired: true,
              key: 'edit-role'

            },
            component: () => import("../views/administration/roles/edit")

          },

        ]

      },
      {
        path: "users",
        name: "administrationUsers",
        meta: {
          title: "Utilisateur",
          authRequired: true,
        },
        children: [
          {
            path: "",
            name: "listeUtilisateur",
            meta: {
              title: "Utilisateurs",
              authRequired: true,
              key: "list-utilisateur"
            },
            component: () => import("../views/administration/users/liste")

          },
          {
            path: "create",
            name: "createUtilisateur",
            meta: {
              title: "Utilisateurs",
              authRequired: true,
              key: "add-utilisateur"
            },
            component: () => import("../views/administration/users/create")

          },
          {
            path: ":id/edit",
            name: "editUtilisateur",
            meta: {
              title: "Rôles",
              authRequired: true,
              key: "edit-utilisateur"
            },
            component: () => import("../views/administration/users/edit")

          },
          {
            path: ":id/affectations",
            name: "affectionUtilisateur",
            meta: {
              title: "Rôles",
              authRequired: true,
              key: "edit-utilisateur"
            },
            component: () => import("../views/administration/users/affectations.vue")

          },

        ]

      },
    ]
  },
  {
    path: "/statistiques",
    name: "statistiques",
    meta: {
      title: "statistiques",
      authRequired: true,
      key: "statistiques"
    },
    children: [
      {
        path: "nbre-de-contribuables-par-taxe",
        name: "contribTaxe",
        meta: {
          title: "Statistiques",
          authRequired: true,
          key: "nbre-contribuable-par-taxe"
        },
        component: () => import("../views/statistiques/baseTaxables/contribTaxe")

      },
      {
        path: "nbre-equipement-par-taxe",
        name: "equipementTaxe",
        meta: {
          title: "Statistiques",
          authRequired: true,
          key: "nbre-equipement-par-taxe"
        },
        component: () => import("../views/statistiques/baseTaxables/equipementTaxe")

      },
      {
        path: "total-transaction-par-taxe",
        name: "transactionTaxe",
        meta: {
          title: "Statistiques",
          authRequired: true,
          key: "total-transaction-par-taxe"
        },
        component: () => import("../views/statistiques/baseTaxables/transactionTaxe.vue")

      },
      {
        path: "contribuable-par-ramification",
        name: "contribRamification",
        meta: {
          title: "Contribuables",
          authRequired: true,
          key: "contribuable-par-ramification"
        },
        component: () => import("../views/statistiques/contribuables/contribRamification.vue")

      },
      {
        path: "transaction-par-ramification",
        name: "transRamification",
        meta: {
          title: "Nombre de transactions par ramification",
          authRequired: true,
          key: "transaction-par-ramification"
        },
        component: () => import("../views/statistiques/transactions/transRamification.vue")

      },
      {
        path: "performances-par-agent",
        name: "agentPerformance",
        meta: {
          title: "Performance des agents",
          authRequired: true,
          key: "performances-par-agent"
        },
        component: () => import("../views/statistiques/agents/agentPerfomance.vue")

      },


    ]
  },

  {
    path: "/unauthorized",
    name: "Unauthorized",
    meta: {
      title: "Non Autorisé",
      authRequired: false,
    },
    component: () => import("../views/auth/500.vue"),
  },



  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },

];